<template>
  <div>
    <h1 class="mb-10">{{ user.selected_company.name }}</h1>
    <input-content :model='model.selected_company' :items='inputItems'/>
    <v-btn color="primary add-btn" class="mr-4" height="48" width="200" @click="addSample">
      Добавить шаблон
    </v-btn>
    <v-btn color="secondary add-btn" height="48" width="200" @click="addLogo">
      Добавить лого
    </v-btn>

    <vue-dialog :model="sampleState"
                :options="dialogOptions"
                :hide-button="true"
                @closeDialog="closeDialog">
      <template-inputs :templates="templates" @onSubmit="sendSample"></template-inputs>
    </vue-dialog>

    <vue-dialog :model="logoState"
                :options="dialogOptions2"
                :hide-button="false"
                @closeDialog="closeDialog"
                @onSubmit="changeLogo(model.selected_company.id)">
      <input-content :sampleState="sampleState" :model="model.selected_company" :items="dialogItems"></input-content>

      <img :src="model.selected_company.logo" alt="" class="company-logo">
    </vue-dialog>
  </div>

</template>

<script>
import InputContent from '@/components/inputs/inputContent.vue';
import vueDialog from "../../components/ui/vueDialog";
import {mapGetters} from 'vuex';
import {companiesService} from "../../services/companies.service";
import {setModel} from "../../utils/formatMask";
import templateInputs from "../../components/inputs/templateInputs";

export default {
  components: {
    InputContent,
    vueDialog,
    templateInputs
  },
  data: () => ({
    model: {},
    templates: [],
    inputItems: [
      {
        type: 'text',
        label: 'ИИН/БИН',
        model: 'bank_details_bin',
        rules: 'required',
        cols: 6,
        readonly: true
      },
      {
        type: 'text',
        label: 'Название',
        model: 'name',
        rules: 'required',
        cols: 6,
        readonly: true
      },
      {
        type: 'text',
        label: 'Юр. название',
        model: 'manager_fullname',
        rules: 'required',
        cols: 6,
        readonly: true
      },
      {
        type: 'text',
        label: 'Юр. адрес',
        model: 'legal_address',
        rules: 'required',
        cols: 6,
        readonly: true
      },
      {
        type: 'text',
        label: 'Факт. адрес',
        model: 'actual_address',
        rules: 'required',
        cols: 6,
        readonly: true
      },
      {
        type: 'text',
        label: 'Контактное лицо',
        model: 'contact_person',
        rules: 'required',
        cols: 6,
        readonly: true
      },
      {
        type: 'text',
        label: 'Телефон',
        mask: '+7 (###) ### ##-##',
        model: 'phone_number',
        rules: 'required',
        cols: 6,
        readonly: true
      },
      {
        type: 'text',
        label: 'Email',
        model: 'email',
        rules: 'email',
        cols: 6,
        readonly: true
      },
      {
        type: 'text',
        label: 'Банковские реквизиты',
        model: 'bank_details_account',
        rules: 'required',
        cols: 6,
        readonly: true
      },
      {
        type: 'text',
        label: 'БИК',
        model: 'bank_details_bik',
        rules: 'required',
        cols: 6,
        readonly: true
      },
      {
        type: 'text',
        label: 'Банк',
        model: 'bank_details_bank',
        rules: 'required',
        cols: 6,
        readonly: true
      },
      {
        type: 'date',
        label: 'Срок лицензии',
        model: 'license_date',
        rules: 'required',
        cols: 6,
        readonly: true
      },
      {
        type: 'textarea',
        label: 'Комментарии',
        model: 'comments',
        rules: 'required',
        cols: 12,
        readonly: true
      }
    ],

    dialogItems: [
      {
        type: 'image',
        label: 'Логотип',
        model: 'logo',
        cols: 12,
        rules: '',
      }
    ],
    dialogOptions: {
      width: '800px',
      title: 'Добавить шаблон',
      button: 'Сохранить'
    },
    dialogOptions2: {
      width: '800px',
      title: 'Добавить лого',
      button: 'Сохранить'
    },
    sampleState: false,
    logoState: false
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    })
  },
  created() {
    this.mutateData()
  },
  destroyed(){
    location.reload()
  },
  methods: {
    async mutateData() {
      this.$loading(true)
      this.model = this.$copy(this.user)
      this.model.selected_company.license_date = this.$moment(Number(this.user.selected_company.license_date) * 1000).format('YYYY-MM-DD')
      const res = await companiesService.getSingleCompany(this.model.selected_company.id)
      this.model.selected_company.email_footer = res.email_footer
      this.model.selected_company.logo = res.logo
      this.templates = await companiesService.getTemplates()
      this.$loading(false)
    },
    addSample() {
      this.sampleState = true
    },
    addLogo() {
      this.logoState = true
    },
    closeDialog() {
      this.sampleState = false
      this.logoState = false
    },
    async changeLogo(id) {
      try {
        this.$loading(true)
        const formData = new FormData()
        formData.append('logo', this.model.selected_company.logo)
        await companiesService.patchCompany(formData, id)
      } catch (e) {
        console.log(e)
      } finally {
        this.$loading(false)
        this.closeDialog()
        await this.mutateData()
      }
    },
    async sendSample(item) {
      try {
        this.$loading(true)
        await companiesService.setTemplates(item)
      } catch (e) {
        console.log(e)
      } finally {
        this.$loading(false)
        this.closeDialog()
        await this.mutateData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-btn {
  margin-top: 20px;
}

.company-logo {
  max-width: 400px;
  margin-top: 20px;
  display: block;
}
</style>