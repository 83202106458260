<template>
  <div>
    <div v-for="(i) in templates" :key="i.template_type" class="mb-4">
      <p class="mb-1">{{ templateLabel(i.template_type) }}</p>
      <keep-alive>
        <editor
            v-if="active"
            apiKey="469ystiyxmnamzivw078tc8ejroxgrdpnersv5ncjsqcuij2"
            v-model="i.email_footer"
            :init="init"
            :disabled="false"
        >
        </editor>
      </keep-alive>
      <v-btn class="my-5" color="primary" height="44" @click="$emit('onSubmit', i)" v-text="`Сохранить ${templateLabel(i.template_type)}`"></v-btn>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    editor: Editor,
  },
  props: {
    templates: Array,
    items: Array,
    label: '',
    sampleState: Boolean
  },
  data(){
    return{
      active: false,
      init: {
          height: 200,
          menubar: false,
          plugins: [
          'advlist autolink lists link image charmap',
          'searchreplace visualblocks code fullscreen',
          'print preview anchor insertdatetime media',
          'paste code help wordcount table',
          ],
          toolbar:
          'undo redo | formatselect | bold italic | \
          alignleft aligncenter alignright | \
          bullist numlist outdent indent | help',
      }
    }
  },
  watch: {
    active(){
      console.log(this.sampleState);
    }
  },
  mounted(){
    console.log(this.templates);
     this.active= true;
  },
  destroyed(){
    this.active= false;
  },
  methods: {
    templateLabel(type) {
      let obj = {
        accommodation: 'Шаблон для проживания',
        tour: 'Шаблон для туров',
        railway: 'Шаблон для Ж/Д',
        bus: 'Шаблон для автобусов',
      }
      return obj[type]
    }
  }
}
</script>